





























































































































































































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { User, UserHives } from '@/types';
import MyHive from '@/components/MyHive.vue';

@Component({
  components: {
    MyHive,
  },
})
export default class MyCreditsDialog extends Vue {
  dialog = false
  loading = false
  valid = false

  newHivesCredits = 0
  newHoneycombsCredits = 0

  get value(): boolean {
    return this.$store.getters.myCreditsDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get userHives(): UserHives {
    return this.currentUser.hives ?? {};
  }

  get hivesCount(): number {
    return this.currentUser.hives && Object.keys(this.currentUser.hives)
      ? Object.keys(this.currentUser.hives).length : 0;
  }

  get hivesCredits(): number {
    return this.currentUser.hivesCredits ?? 0;
  }

  get hivesCreditsText(): string {
    if (this.hivesCredits === 0) return "Non hai fiorini d'oro";
    if (this.hivesCredits === 1) return "Hai 1 fiorino d'oro!";
    return `Hai ${this.hivesCredits} fiorini d'oro!`;
  }

  get hivesCreditsCaption(): string {
    if (this.hivesCredits === 0) return "Aggiungine uno se vuoi adottare un'arnia";
    if (this.hivesCredits === 1) return "Con cui puoi adottare un'arnia";
    return `Con cui puoi adottare ${this.hivesCredits} arnie`;
  }

  get honeycombsCredits(): number {
    return this.currentUser.honeycombsCredits ?? 0;
  }

  get honeycombsCreditsText(): string {
    if (this.honeycombsCredits === 0) return "Non hai fiorini d'argento";
    if (this.honeycombsCredits === 1) return "Hai 1 fiorino d'argento!";
    return `Hai ${this.honeycombsCredits} fiorini d'argento!`;
  }

  get honeycombsCreditsCaption(): string {
    if (this.honeycombsCredits === 0) return 'Aggiungine uno se vuoi ottenere il miele di un favo';
    if (this.honeycombsCredits === 1) return 'Puoi ottenere il miele di un favo';
    return `Puoi ottenere il miele di ${this.honeycombsCredits} favi`;
  }

  get hivesCreditsCoins(): string {
    if (this.newHivesCredits === 0) return '0';
    return `+${this.newHivesCredits}`;
  }

  get honeycombsCreditsCoins(): string {
    if (this.newHoneycombsCredits === 0) return '0';
    return `+${this.newHoneycombsCredits}`;
  }

  // FIXME: Memorizzare i prezzi altrove
  get hivesCreditsPrice(): number {
    return 15.0;
  }

  // FIXME: Memorizzare i prezzi altrove
  get honeycombsCreditsPrice(): number {
    // return 12;
    return 13.0; // Aumentato il 26/10/2023
  }

  get hivesCreditsPriceText(): string {
    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    });

    return formatter.format(this.hivesCreditsPrice * this.newHivesCredits);
  }

  get honeycombsCreditsPriceText(): string {
    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    });

    return formatter.format(this.honeycombsCreditsPrice * this.newHoneycombsCredits);
  }

  get dialogTitle(): string {
    return this.newHivesCredits === 0 && this.newHoneycombsCredits === 0
      ? 'I miei fiorini'
      : 'Ottieni più fiorini';
  }

  get buyButtonText(): string {
    if (this.newHivesCredits === 0 && this.newHoneycombsCredits === 0) return 'Ottieni più fiorini';

    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    });

    const amount = formatter
      .format(this.hivesCreditsPrice * this.newHivesCredits
      + this.honeycombsCreditsPrice * this.newHoneycombsCredits);

    return `Paga ${amount}`;
  }

  get summary(): boolean {
    return this.newHivesCredits > 0 || this.newHoneycombsCredits > 0;
  }

  get summaryHtml(): string {
    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    });

    const amount = formatter
      .format(this.hivesCreditsPrice * this.newHivesCredits
      + this.honeycombsCreditsPrice * this.newHoneycombsCredits);

    // const totHivesCredits = this.hivesCredits + this.newHivesCredits;
    // const totHoneycombsCredits = this.honeycombsCredits + this.newHoneycombsCredits;

    let text = 'Proseguendo acquisterai ';

    if (this.newHivesCredits === 1) text += "un fiorino d'oro";
    else if (this.newHivesCredits > 1) text += `${this.newHivesCredits} fiorini d'oro`;

    if (this.newHivesCredits > 0 && this.newHoneycombsCredits > 0) text += ' e ';

    if (this.newHoneycombsCredits === 1) text += "un fiorino d'argento";
    else if (this.newHoneycombsCredits > 1) text += `${this.newHoneycombsCredits} fiorini d'argento`;

    text += `, spendendo in totale <strong>${amount}</strong>.`;

    return text;
  }

  onIncrement(type: 'hivesCredits' | 'honeycombsCredits') {
    if (type === 'hivesCredits') this.newHivesCredits += 1;
    if (type === 'honeycombsCredits') this.newHoneycombsCredits += 1;
  }

  onDecrement(type: 'hivesCredits' | 'honeycombsCredits') {
    if (type === 'hivesCredits' && this.newHivesCredits > 0) this.newHivesCredits -= 1;
    if (type === 'honeycombsCredits' && this.newHoneycombsCredits > 0) this.newHoneycombsCredits -= 1;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'MyCreditsDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (val) {
      this.newHivesCredits = 0;
      this.newHoneycombsCredits = 0;

      this.loading = false;
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'MyCreditsDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'myCredits');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onHelpAdoption() {
    this.$store.dispatch('openDialog', 'helpAdoption');
  }

  onHelpHoneycomb() {
    this.$store.dispatch('openDialog', 'helpHoneycomb');
  }

  onLogin() {
    const debugPrefix = 'MyCreditsDialog: onLogin()';
    console.log(debugPrefix); // eslint-disable-line no-console

    this.dialog = false;
    this.$store.dispatch('openDialog', 'login');
  }

  onBuyCredits() {
    const debugPrefix = 'MyCreditsDialog: onBuyCredits()';
    console.log(debugPrefix); // eslint-disable-line no-console

    // Svuota il carrello esistente
    this.$store.dispatch('resetCart');

    // Aggiunge i fiorini d'oro al carrello
    if (this.newHivesCredits > 0) {
      this.$store.dispatch('addToCart', {
        product: {
          title: "Fiorino d'oro",
          descr: "Ogni fiorino d'oro dà diritto all'adozione di un'arnia",
          code: 'fiorino-oro',
          price: this.hivesCreditsPrice,
        },
        quantity: this.newHivesCredits,
      });
    }

    // Aggiunge i fiorini d'argento al carrello
    if (this.newHoneycombsCredits > 0) {
      this.$store.dispatch('addToCart', {
        product: {
          title: "Fiorino d'argento",
          descr: "Ogni fiorino d'argento dà diritto ad ottenere 1 Kg di miele prodotto dai tuoi favi",
          code: 'fiorino-argento',
          price: this.honeycombsCreditsPrice,
        },
        quantity: this.newHoneycombsCredits,
      });
    }

    this.dialog = false;
    this.$store.dispatch('openDialog', 'buyCredits');
  }

  onCancel() {
    this.dialog = false;
  }
}
